.about-section {
    padding: 80px 0;
    background-color: #f9f9f9;
  }
  
  .container {
    max-width: 1500px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .section-title {
    text-align: center;
    margin-bottom: 50px;
  }
  
  .section-title h2 {
    font-size: 36px;
    color: #333;
  }
  
  .section-title p {
    font-size: 18px;
    color: #777;
  }
  
  .about-content {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    justify-content: space-between;
    align-items: left;
  }
  
  .about-text {
    flex: 1;
    padding-right: 20px;
    margin-bottom: 20px; /* Add margin bottom to create space between text and image */
  }
  
  .about-text h3 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .about-text p {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
  }
  
  .about-image {
    flex: 1; /* Take up remaining space */
    max-width: 400px;
    margin-left: auto; /* Push image to the right on smaller screens */
  }
  
  .about-image img {
    width: 100%;
    max-width: 100%; /* Ensure image doesn't exceed its container */
    display: block;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .about-content {
      flex-direction: column; /* Stack items vertically on smaller screens */
      align-items: center;
    }
  
    .about-text {
      padding-right: 0;
      text-align: center; /* Center text on smaller screens */
    }
  
    .about-image {
      margin-left: 0;
      margin-top: 30px; /* Add space between text and image on smaller screens */
    }
  }
  