.review-heading {
    color: #333;
    font-size: 32px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
}

.review-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #f8f8f8;
}

.review-form {
    max-width: 500px;
    width: 80%;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.review-form label {
    display: block;
    margin-bottom: 10px;
    color: #555;
    text-align: left;
    font-weight: bold;
}

.review-form input[type="text"],
.review-form textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.review-form button {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.review-form button:hover {
    background-color: #222;
}

.review-form .rating-stars {
    font-size: 32px;
    text-align: center;
}

.review-form .rating-stars span {
    color: #FFD700;
    cursor: pointer;
}

.review-form .rating-stars span:hover,
.review-form .rating-stars span.active {
    color: #FFD700;
}

/* Media queries for responsiveness */
@media screen and (min-width: 768px) {
  .review-container {
    padding: 50px 20px;
  }
}

@media screen and (min-width: 992px) {
  .review-form {
    max-width: 800px;
  }
}
