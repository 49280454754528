.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #000000;
    color: white;
  }
  
  .navbar-brand {
    display: flex;
    align-items: center;
  }
  
  .navbar-logo {
    width: 40px; /* Adjust the size of your logo */
    margin-right: 10px;
  }
  
  .brand-text {
    font-size: 18px;
  }
  
  .toggle-button {
    display: none; /* Initially hide the toggle button */
    font-size: 24px;
    cursor: pointer;
  }
  
  .navbar-links {
    list-style: none;
    display: flex;
    color: #ff0000; /* Change the color to your desired color */
    position: relative; /* Ensure navbar links are relatively positioned */
    z-index: 1; /* Set a higher z-index value */
}

  
  .navbar-item {
    margin-right: 20px;
    color: #f4f4f4;
    text-decoration: none;
  }
  
  /* Media query for responsive design */
  @media screen and (max-width: 768px) {
    .toggle-button {
      display: block; /* Show the toggle button on smaller screens */
    }
  
    .navbar-links {
      display: none; /* Hide the navigation links by default on smaller screens */
      flex-direction: column;
      position: absolute;
      top: 80px; /* Adjust the distance from the top */
      left: 0;
      width: 100%;
      color: black;
      background-color: rgb(252, 252, 252);
      padding: 10px;
    }
  
    .navbar-links.open {
      display: flex; /* Show the navigation links when the open class is applied */
    }
  
    .navbar-item {
      margin-bottom: 10px;
      margin-right: 0;
      text-decoration: none;
      color: #000000; /* Adjust the margin for responsive layout */
    }
  }
  