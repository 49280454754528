.admin-reviews-page {
    display: flex;
  }
  
  .admin-reviews-container {
    flex: 1;
    margin-left: 20px;
  }
  
  .reviews-table {
    width: 90%;
    border-collapse: collapse;
  }
  
  .reviews-table th, .reviews-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .reviews-table th {
    background-color: #f2f2f2;
    font-weight: bold;
    text-align: left;
  }
  
  .reviews-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .reviews-table tbody tr:hover {
    background-color: #ddd;
  }
  /* Adjustments for smaller screens */
@media screen and (max-width: 768px) {
    .admin-reviews-container {
      margin-left: 10px; /* Adjust margin for better spacing */
    }
    
    .reviews-table th,
    .reviews-table td {
      padding: 6px; /* Reduce padding */
      font-size: 12px; /* Reduce font size */
    }
  }
  
  /* Spinner animation */
@keyframes spinner {
    to {transform: rotate(360deg);}
  }
  
  .spinner {
    margin: 0 auto;
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top-color: #333;
    border-radius: 50%;
    animation: spinner 1s linear infinite;
  }
  