.siragirivel-carousel-container {
  width: 80%; /* Adjust width as needed */
  margin: 0 auto;
}

.product-slide {
  text-align: center;
  padding: 20px;
}

.product-box {
  border-radius: 8px;
  padding: 10px; /* Reduce padding */
  background-color: #fff;
  max-width: 80%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Image styles */
.product-box img {
  align-items: center;
  max-width: 100%; /* Ensure the image doesn't exceed the width of its container */
  border-radius: 8px; /* Optional: Add rounded corners to the image */
  margin-bottom: 15px; /* Optional: Add spacing between the image and other content */
}

/* Remove text underline from product names and descriptions */
.product-box a {
  text-decoration: none; /* Remove underline */
}

/* Optional: Add specific styles for product details */
.product-box h3,
.product-box p {
  color: #333;
}

/* Responsive styles */
@media (max-width: 768px) {
  .siragirivel-carousel-container {
    width: 90%; /* Adjust width for smaller screens */
  }
}
