.app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85vh;
    position: relative;
}

.booking-system {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    margin: 20px;
}

.vehicle-form select,
.vehicle-form input[type="text"],
.phone-verification-form input[type="number"],
.otp-form input[type="number"],
.phone-verification-form button,
.otp-form button {
    width: 100%; /* Set width to 100% for consistent width */
    height: 40px; /* Set height to 40px for consistent height */
    padding: 10px; /* Keep the same padding for all inputs */
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box; /* Ensure padding and border are included in width calculation */
}

.phone-verification-form button,
.otp-form button {
    display: block; /* Ensure buttons take full width */
}

/* Adjust button styles */
.phone-verification-form button,
.otp-form button {
    width: 100%;
    background-color: #000000;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

/* Adjust button styles on hover */
.phone-verification-form button:hover,
.otp-form button:hover {
    background-color: #000000;
}


.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('battery.jpg');
    background-size: cover;
    background-position: center;
    z-index: -1;
    
}

@media only screen and (max-width: 768px) {
    .app-container {
        justify-content: flex-start;
        padding: 30px;
    }
    
    .booking-system {
        margin: 0;
    }
    
    .vehicle-form select,
    .vehicle-form input[type="text"],
    .phone-verification-form input[type="number"],
    .otp-form input[type="number"] {
        width: calc(100% - 20px);
    }
}
