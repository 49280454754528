.admin-login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

.admin-login {
    max-width: 400px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px; /* Adding curved corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.admin-login h2 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.admin-login .error {
    color: red;
    margin-bottom: 10px;
}

.admin-login input[type="email"],
.admin-login input[type="password"] {
    width: calc(100% - 24px); /* Adjusting width to account for padding */
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 20px;
    transition: border-color 0.3s ease;
}

.admin-login input[type="email"]:focus,
.admin-login input[type="password"]:focus {
    border-color: #000000;
}

.admin-login button {
    width: 100%;
    padding: 12px;
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.admin-login button:hover {
    background-color: #353535;
}


.sidebar {
    width: 250px;
    height: 100vh;
    background-color: #333;
    color: white;
    overflow-y: auto;
    transition: width 0.3s ease;
}

.sidebar.open {
    width: 0;
}

.sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.toggle-button {
    font-size: 20px;
    cursor: pointer;
    border: none;
    background: none;
    color: white;
}

.sidebar-nav {
    list-style-type: none;
    padding: 0;
}

.sidebar-nav li {
    padding: 10px;
}

.sidebar-nav li a {
    color: white;
    text-decoration: none;
}
.sidebar-nav button{
    background-color: #ffffff;
    border-radius: 5px;
    font-style: normal;
    color: #000000;
    text-decoration: none;
    width: 100px;
    height: 30px;
    
}
.red-text {
    color: red;
    font-weight: 800;
  }
  

.content {
    flex: 1;
    padding: 20px;
}

.open-toggle-button {
    display: none;
}
.logout
{
    color: aqua;
}

@media screen and (max-width: 768px) {
    .open-toggle-button {
        display: block;
        position: absolute;
        top: 80px;
        left: 10px;
        z-index: 1000;
    }
}
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #333;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  