.price-list {
    width: 60%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .price-list h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .price-list ul {
    list-style: none;
    padding: 0;
  }
  
  .price-list li {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  }
  
  .price-list li span {
    font-size: 18px;
  }
  
  .price-list li span:last-child {
    font-weight: bold;
    color: #000000;
  }
  