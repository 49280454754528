/* GeneralService.css */

.general-service-container {
    max-width: 1350px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  
  .general-service-image {
    width: 100%;
    max-height: 300px; /* Adjust as needed */
    object-fit: cover;
    border-radius: 20px 20px 0 0;
    transition: transform 0.3s ease;
  }
  .h3
  {
    text-align:start;
  }
  .general-service-image:hover {
    transform: scale(1.05);
  }
  
  .general-service-title {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  .general-service-text,
  .general-service-list {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #555;
  }
  
  .general-service-list {
    margin-left: 30px;
  }
  
  .general-service-subtitle {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .general-service-button {
    background-color: #000000;
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    display: block;
    margin: 0 auto;
    text-decoration: none;
  }
  
  .general-service-button:hover {
    background-color: #000000;
  }
  
  @media (max-width: 600px) {
    .general-service-container {
      padding: 15px;
    }
    
    .general-service-title {
      font-size: 2rem;
    }
    
    .general-service-text,
    .general-service-list {
      font-size: 1rem;
      margin-bottom: 15px;
    }
    
    .general-service-subtitle {
      font-size: 1.5rem;
    }
  }
  