.admin-bookings-page {
    display: flex;
  }
  
  .admin-bookings-container {
    flex-grow: 1;
    padding: 20px;
  }
  
  .bookings-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .bookings-table th,
  .bookings-table td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  .bookings-table th {
    background-color: #f2f2f2;
  }
  
  .completed-status {
    background-color: #90ee90; /* Light green */
  }
  
  .bookings-table th {
    background-color: #f2f2f2;
  }
  
  .bookings-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .bookings-table tr:hover {
    background-color: #ddd;
  }
  
  .bookings-table button {
    margin-right: 5px;
  }
 /* Adjustments for smaller screens */
@media screen and (max-width: 768px) {
    .admin-bookings-container {
      padding: 10px;
    }
    
    .bookings-table th,
    .bookings-table td {
      padding: 6px; /* Reduce padding */
      font-size: 12px; /* Reduce font size */
    }
  }
  /* Spinner animation */
@keyframes spinner {
    to {transform: rotate(360deg);}
  }
  
  .spinner {
    margin: 0 auto;
    width: 40px;
    height: 40px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top-color: #333;
    border-radius: 50%;
    animation: spinner 1s linear infinite;
  }
  